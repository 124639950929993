export const dropDownResult = (selectedlist, selectedvalue) => {
  var checkenvironment;

  return checkenvironment;
};
export const useErrorNavigation = (error) => {
  if (error) {
    if (error.networkError && error.networkError.statusCode === 401) {
      // if (error.networkError) {
      // console.log(error);
      localStorage.removeItem("CognitoIdentityServiceProvider.auth");
      localStorage.removeItem("_token");
      localStorage.removeItem("TokenOptixIdentityServiceProvider.usr");
      localStorage.removeItem("TokenOptixIdentityServiceProvider.logtype");
      localStorage.removeItem("TokenOptixIdentityServiceProvider.auth");
      window.location.href = "/";
    }
  }
};
// export function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }
export function simplifyNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "B";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "M";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "K";
  } else {
    if (number !== undefined && number !== null) {
      return number.toString();
    } else {
      return "Null";
    }
  }
}

export function formatDate(inputDate) {
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = new Date(inputDate).toLocaleString("en-US", options);
  return formattedDate;
}

export function formatFileSize(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (bytes >= gigabyte) {
    return (bytes / gigabyte).toFixed(2) + " GB";
  } else if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return bytes + " Bytes";
  }
}
export function formatCurrentTime() {
  const now = new Date();
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const day = days[now.getDay()];
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${day} ${formattedHours}.${formattedMinutes}${ampm}`;
  return formattedTime;
}
export function getCurrentDayAndTime() {
  const options = {
    weekday: "short",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formatter = new Intl.DateTimeFormat("ist", options);
  const currentDate = new Date();
  const formattedDate = formatter.format(currentDate);
  return formattedDate;
}
export function generateTransactionId() {
  const timestamp = Date.now().toString();
  const randomNum = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0");
  return `${timestamp}-${randomNum}`;
}
export const htmlRemove = (event) => {
  const htmlContent = event;
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  return tempElement.textContent;
};
export function generateRandomFourDigitNumber() {
  return Math.floor(1000 + Math.random() * 9000);
}
export function capitalizeWords(inputString) {
  if (inputString != null) {
    const words = inputString.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const resultString = capitalizedWords.join(" ");

    return resultString;
  }
}
export function formatDateHistory(dateString) {
  const date = new Date(dateString);
  const currentDate = new Date();
  const isCurrentWeek =
    date >= currentDate && date - currentDate <= 7 * 24 * 60 * 60 * 1000;
  if (isCurrentWeek) {
    const options = {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  } else {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
}
export function formatDatewithtime(inputDate) {
  if (inputDate) {
    const date = new Date(inputDate);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;
    return formattedDate;
  }
}
