import React, { useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { GoogleLogin } from "@react-oauth/google";
// import { Button } from "react-bootstrap";
import { message, Button, Modal, Form, Input, Alert } from "antd";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginEmailcheck,
  azureAdEmailCheck,
} from "../../components/api/accountAction";

import { Icon } from "@iconify/react";
import {
  signUp,
  resendVerificationCode,
  VerificationEamil,
} from "../Amplify/auth-config";

const SignUp = ({
  handleSignUpClick,
  onFinishSubmitForm,
  buttonloader,
  setButtonLoader,
  email,
  setEmail,
  registerstatus,
  setRegisterStatus,
  validatePassword,
  onOTPSubmitForm,
  resetOTP,
  validateConfirmPassword,
}) => {
  const navigateFn = useNavigate();
  const [form] = Form.useForm();
  //inputs
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //end inputs
  // const dispatch = useDispatch();
  // const [buttonloader, setButtonLoader] = useState(false);
  //azure ad
  const [open, setOpen] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [registerstatus, setRegisterStatus] = useState(true);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleSignin = () => {
    navigateFn("/");
  };

  return (
    <>
      <div className="main-container" style={{ height: "100vh" }}>
        <div className="login-padding mobileView">
          <div className="text-center">
            {" "}
            {/* <img
            src={navLogo}
            className="navLogoSignIn"
            onClick={() => navigate("/")}
          />{" "} */}
          </div>
          <div className="mobileHeader">
            {/* <div className="mt-2 sign-in-subheader mobile-logo">
              <img
                src={aivolvexlogo}
                alt="logo"
                className="mobile_signin_logo"
              />
            </div> */}
            <div className="mt-2 sign-in-subheader desktop-logo">
              {/* <img src={aivolvex} alt="logo" className="signin_logo" /> */}
              {/* <span className="logo-peta-login">beta</span> */}
            </div>
            <div className="mt-1">
              <p className="signin-header aivolvex-font"> Let's Get Started </p>
            </div>
          </div>

          {/* <div className="mt-4 w-100 row">
          <div className="col-md-6 col-lg-6 w-50"> */}
          <div className="d-lg-flex w-100 mt-3 socials-div-mobile"></div>
          {registerstatus ? (
            <>
              <div>
                <Form onFinish={onFinishSubmitForm} className="mt-5">
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter First Name",
                      },
                      { max: 20, message: "Cannot Exceed 20 Characters" },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter First Name"
                      className="form-control signin border-0"
                    />
                  </Form.Item>

                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Last Name",
                      },
                      { max: 20, message: "Cannot Exceed 20 Characters" },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter Last Name"
                      className="form-control signin border-0"
                    />
                  </Form.Item>

                  <Form.Item
                    name="emailid"
                    label="Email ID"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Email ID",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter Email ID"
                      className="form-control signin border-0"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Enter Password",
                    //   },
                    // ]}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Password",
                      },
                      validatePassword,
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Please Enter Password"
                      className="form-control signin border-0"
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmpassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      validateConfirmPassword,
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Please Enter Confirm Password"
                      className="form-control signin border-0"
                    />
                  </Form.Item>

                  <Form.Item className="text-right">
                    <Button
                      htmlType="submit"
                      className="button-primary btn btn-primary-new fontSize10 width-100"
                      loading={buttonloader}
                    >
                      SIGN UP
                    </Button>
                  </Form.Item>
                </Form>

                <div className="web-signup-here-btn">
                  <span className="signup-here-web-prefix ">
                    Already have an account?
                  </span>
                  <span
                    className="signup-web-here cursor-pointer"
                    onClick={handleSignin}
                  >
                    Sign in here
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Form onFinish={onOTPSubmitForm} className="mt-5">
                  <Form.Item
                    name="verification_code"
                    label="Verification Code"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Verification Code",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter Verification Code"
                      className="form-control signin border-0"
                    />
                  </Form.Item>
                  <div className="mb-3">
                    <div className="custom-control custom-checkbox d-flex">
                      <div className="d-flex-remember-w"></div>
                      <div className="forgotPasswordMobileView w-50">
                        <p
                          className="forgot-password text-right float-end cursor-pointer signIn-font-remeber aivolvex-font"
                          onClick={resetOTP}
                        >
                          Didn't Receive OTP?
                        </p>
                      </div>
                    </div>
                  </div>
                  <Form.Item className="text-right">
                    <Button
                      htmlType="submit"
                      className="button-primary btn btn-primary-new fontSize10 width-100"
                      loading={buttonloader}
                    >
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
