import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { Col, Row, Popconfirm, DatePicker } from "antd";
import preloader from "../../../assets/loader/pre_loader.gif";
import "../CSS/dashboardnew.scss";
import {
  getmodalchart,
  gettokenagent,
  gettopuser,
  getyearusage,
} from "../../api/accountAction";

const UserWiseUsage = () => {
  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.usr")
  );
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );

  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [labelagent, setLabelAgent] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [numericagent, setNumericAgent] = useState([]);
  const [labeltopuser, setLabelTopUser] = useState([]);
  const [numerictopuser, setNumericTopUser] = useState([]);
  const [labelyearusage, setLabelYearUsage] = useState([]);
  const [numericyearusage, setNumericYearUsage] = useState([]);
  const [allocatedtoken, setAllocatedtoken] = useState([]);
  const [dashboardDate, setDashboardDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );

  const date = new Date(dashboardDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    value
      ? setDashboardDate(value.format(dateFormat))
      : setDashboardDate(
          `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`
        );
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const monthFormat = "YYYY/MM";
  useEffect(() => {
    const appmonthwise = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(gettokenagent(appmonthwise))
      .unwrap()
      .then(({ data }) => {
        const appcredit = JSON.parse(data.token_month_wise);
        const currentDateValue = new Date(dashboardDate);
        const currentYear = currentDateValue.getFullYear();
        const currentMonth = (currentDateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");

        const currentMonthData = appcredit.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData) {
          currentMonthData.sort(
            (a, b) => b.total_token_used - a.total_token_used
          );
          const top5Data = currentMonthData.slice(0, 5);
          const label_chart = top5Data.map((item) => item.app_name);
          setLabelAgent(label_chart);
          const chartdata = top5Data.map((item) => item.total_token_used);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericAgent(numericValues_db);
        }
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    const top5user = {
      user_id: userName,
      year: year,
      month: month,
    };
    setPreloaderStatus(true);
    dispatch(gettopuser(top5user))
      .unwrap()
      .then(({ data }) => {
        const gettopuser = JSON.parse(data.top_user_token);
        const label_chart = gettopuser.map((item) => item.user_id);
        setLabelTopUser(label_chart);
        const chartdata = gettopuser.map((item) => item.total_tokens);
        const numericValues_db = chartdata.map((value) => parseInt(value, 10));
        setNumericTopUser(numericValues_db);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });

    const input = {
      user_id: userName,
      year: year,
    };
    setPreloaderStatus(true);
    dispatch(getyearusage(input))
      .unwrap()
      .then(({ data }) => {
        const getyearusage = JSON.parse(data.year_total_count);
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const allMonthsData = Array.from({ length: 12 }, (_, i) => {
          const monthData = getyearusage.find((item) => item.month === i + 1);
          return monthData
            ? monthData
            : { month: i + 1, total_tokens: 0, tokens_used: 0 };
        });

        allMonthsData.sort((a, b) => (a.month > b.month ? 1 : -1));

        const labelYearUsage = allMonthsData.map(
          (item) => monthNames[item.month - 1]
        );

        setLabelYearUsage(labelYearUsage);

        const numericValues_db = allMonthsData.map((item) =>
          parseInt(item.total_tokens, 10)
        );
        setNumericYearUsage(numericValues_db);
        const tokenused = allMonthsData.map((item) =>
          parseInt(item.tokens_used, 10)
        );
        setAllocatedtoken(tokenused);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });

    const questioncount = {
      user_id: userName,
      date_time: dashboardDate,
    };
    setPreloaderStatus(true);
    dispatch(getmodalchart(questioncount))
      .unwrap()
      .then(({ data }) => {
        const modalquestion = JSON.parse(data.model_wise_question_count);
        if (modalquestion) {
          const label_chart = modalquestion.map((item) => {
            if (item.model_name) {
              return item.model_name;
            } else {
              return "empty";
            }
          });
          setLabelChart(label_chart);

          const chartdata = modalquestion.map((item) => item.question_count);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        } else {
          console.log("No apps found.");
        }
        setPreloaderStatus(false);
      })

      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
  }, [dashboardDate]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[date.getMonth()];

  const seriestopuser = numerictopuser;
  const chartDataTopUser = {
    labels: labelchart,
    series: seriestopuser,
  };
  const topuseroptions = {
    chart: {
      type: "pie",
    },
    labels: labeltopuser,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Top 5 Users by Tokens`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const userseries = numericagent;

  const chartdata = {
    labels: labelagent,
    series: userseries,
  };

  const useroptions = {
    chart: {
      type: "pie",
    },
    labels: labelagent,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName}  Month Top 5 Apps by Tokens`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const PopConfirmTemplate = (props) => {
    return (
      <>
        <div className="volvex-shared-form">
          <p className="aivolvex-dashboard-popconfirm-content white-color">
            {props.content}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <NavBar /> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <NavBar />
          <div className="body-margin-top">
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-left"
              >
                <h4 className="dashboard-credit aivolvex-font">
                  Top 5 Users and Apps
                </h4>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-end"
              >
                <DatePicker
                  className="dashboard-datepicker"
                  defaultValue={dayjs(dashboardDate, monthFormat)}
                  format={monthFormat}
                  picker="month"
                  onChange={handleMonthChange}
                  disabledDate={disabledDate}
                />
              </Col>
            </Row>
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart" className="volvex-about-popconfirm">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <ReactApexChart
                            options={topuseroptions}
                            series={chartDataTopUser.series}
                            type="pie"
                            height={234}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <ReactApexChart
                            options={useroptions}
                            series={chartdata.series}
                            type="pie"
                            height={234}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserWiseUsage;
