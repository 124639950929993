import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "./client";
import without_client from "./without_client";
import { useErrorNavigation } from "../customhook/CustomHoom";
import {
  ONBOARD_AVATAR,
  APPHUB_LIST_APPS,
  LOGIN_EMAIL_CHECK,
  AZUREADEMAILCHECK,
  GET_CREDIT_MONTH,
  GET_TOKEN_AGENT,
  GET_MODAL_CHART,
  GET_CREDIT_DAY,
  GET_PROMPT_DAY,
  GET_USAGE_YEAR,
  WELCOME_EMAIL,
  APPHUB_INSERT_APPS,
  APPHUB_UPDATE_APPS,
  APPHUB_DELETE_APPS,
  GET_TOP_USER,
  APPHUB_APP_OPTIONS,
  APPHUB_CALCULATOR,
  APPHUB_RECORD_MODELS,
  REPORT_TOTAL_PAGES,
  REPORT_TABLE_VALUES,
  GET_TOKEN_DETAILS,
  APPHUB_TOTAL_RECORDS,
  GET_PRICE_MONTH,
  PRICING_INFO,
  MODELWISE_PRICING,
  DAILY_PRICING,
  APP_PRICING_TABLE,
  API_GET_TOKEN_AGENT,
  API_GET_TOP_USER,
  API_GET_USAGE_YEAR,
  API_GET_MODAL_CHART,
  LAST_THREE_MONTH_COST,
  APPHUB_APP_TOKEN,
} from "./accountquery";

export const creditdaywise = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const creditday = await client.mutate({
        mutation: GET_CREDIT_DAY,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditday;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const onboardAvatar = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: ONBOARD_AVATAR,
        variables: {
          about: data.about,
          avatar_title: data.avatar_title,
          context: data.context,
          pod_category: data.pod_category,
          avatar_image: data.image,
          version: data.version,
          avatar_owner: data.avatar_owner,
        },
      });
      return onboardavatar;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const welcomeEmail = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const signupemail = await without_client.mutate({
        mutation: WELCOME_EMAIL,
        variables: {
          user_id: data.email,
        },
      });
      return signupemail;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loginEmailcheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateAvatar = await client.mutate({
        mutation: LOGIN_EMAIL_CHECK,
        variables: {
          user_id: data.user_id,
        },
      });
      return updateAvatar;
    } catch (error) {
      console.log(error);
      // useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const azureAdEmailCheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const azuread = await without_client.mutate({
        mutation: AZUREADEMAILCHECK,
        variables: {
          email: data,
        },
      });
      return azuread;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getcreditavatar = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: GET_CREDIT_MONTH,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getpricemonth = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: GET_PRICE_MONTH,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettokenagent = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: GET_TOKEN_AGENT,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apigettokenagent = createAsyncThunk(
  "avatar/credit/avatar",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: API_GET_TOKEN_AGENT,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apphublistapps = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: APPHUB_LIST_APPS,
        variables: {
          user_id: data.user_id,
          drop_down: data.drop_down,
          text: data.text,
          page: data.page,
          limit: data.limit,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apphubtotalrecords = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: APPHUB_TOTAL_RECORDS,
        variables: {
          user_id: data.user_id,
          drop_down: data.drop_down,
          text: data.text,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apphubappoptions = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    try {
      const creditavatar = await client.mutate({
        mutation: APPHUB_APP_OPTIONS,
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const apphubrecordmodels = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    try {
      const calc = await client.mutate({
        mutation: APPHUB_RECORD_MODELS,
        variables: {
          app_id: data,
        },
      });
      return calc;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apphubcalculator = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    try {
      const calc = await client.mutate({
        mutation: APPHUB_CALCULATOR,
        variables: {
          input_tokens: data.input_tokens,
          output_tokens: data.output_tokens,
          provider: data.provider,
          model_name: data.model_name,
          api_type: data.api_type,
          page: data.page,
          input_character: data.input_character,
          output_character: data.output_character,
        },
      });
      return calc;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apphubinsertapps = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const creditavatar = await client.mutate({
        mutation: APPHUB_INSERT_APPS,
        variables: {
          app_name: data.values.app_name,
          description: data.values.description,
          model_name: data.values.model_name,
          title: data.values.title,
          email_id: data.email_id,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const apphubupdateapps = createAsyncThunk(
  "apphub/list/apps",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const creditavatar = await client.mutate({
        mutation: APPHUB_UPDATE_APPS,
        variables: {
          app_name: data.values.app_name,
          description: data.values.description,
          model_name: data.values.model_name,
          app_id: data.app_id,
          title: data.values.title,
        },
      });
      return creditavatar;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//apphubdeleteapps
export const apphubdeleteapps = createAsyncThunk(
  "token/delete",
  async (data, { rejectWithValue }) => {
    try {
      const credit = await client.mutate({
        mutation: APPHUB_DELETE_APPS,
        variables: {
          app_id: data,
        },
      });
      return credit;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getmodalchart = createAsyncThunk(
  "avatar/modal",
  async (data, { rejectWithValue }) => {
    try {
      const modalchart = await client.mutate({
        mutation: GET_MODAL_CHART,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return modalchart;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apigetmodalchart = createAsyncThunk(
  "avatar/modal",
  async (data, { rejectWithValue }) => {
    try {
      const modalchart = await client.mutate({
        mutation: API_GET_MODAL_CHART,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return modalchart;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getpromptdaywise = createAsyncThunk(
  "token/credit",
  async (data, { rejectWithValue }) => {
    try {
      const creditday = await client.mutate({
        mutation: GET_PROMPT_DAY,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return creditday;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettopuser = createAsyncThunk(
  "token/totalprompts",
  async (data, { rejectWithValue }) => {
    try {
      const topuser = await client.mutate({
        mutation: GET_TOP_USER,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
        },
      });
      return topuser;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apigettopuser = createAsyncThunk(
  "token/totalprompts",
  async (data, { rejectWithValue }) => {
    try {
      const topuser = await client.mutate({
        mutation: API_GET_TOP_USER,
        variables: {
          user_id: data.user_id,
          year: data.year,
          month: data.month,
        },
      });
      return topuser;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getyearusage = createAsyncThunk(
  "token/yearusage",
  async (data, { rejectWithValue }) => {
    try {
      const yearusage = await client.mutate({
        mutation: GET_USAGE_YEAR,
        variables: {
          user_id: data.user_id,
          year: data.year,
        },
      });
      return yearusage;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apigetyearusage = createAsyncThunk(
  "token/yearusage",
  async (data, { rejectWithValue }) => {
    try {
      const yearusage = await client.mutate({
        mutation: API_GET_USAGE_YEAR,
        variables: {
          user_id: data.user_id,
          year: data.year,
        },
      });
      return yearusage;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const reporttotalpages = createAsyncThunk(
  "token/yearusage",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: REPORT_TOTAL_PAGES,
        variables: {
          drop_down: data.drop_down,
          text: data.text,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const gettokendetails = createAsyncThunk(
  "token/yearusage",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: GET_TOKEN_DETAILS,
        variables: {
          id: data.id,
          api_type: data.api_type,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const reporttabledata = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: REPORT_TABLE_VALUES,
        variables: {
          drop_down: data.drop_down,
          text: data.text,
          limit: data.limit,
          page: data.page,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const pricinginfo = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: PRICING_INFO,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const modelwisepricing = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: MODELWISE_PRICING,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const apppricingtable = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: APP_PRICING_TABLE,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLastThreeMonthCost = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: LAST_THREE_MONTH_COST,
        variables: {
          date_time: data.date_time,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const dailypricing = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    try {
      const value = await client.mutate({
        mutation: DAILY_PRICING,
        variables: {
          user_id: data.user_id,
          date_time: data.date_time,
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const tokenlist = createAsyncThunk(
  "token/value",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const value = await client.mutate({
        mutation: APPHUB_APP_TOKEN,
        variables: {
          date_time: data.date_time,
          api_type:data.api_type
        },
      });
      return value;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
