import { memo, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { getLastThreeMonthCost } from "../../api/accountAction";

const PieChart = (props) => {
  const dispatch = useDispatch();
  //state
  const [chart, setChart] = useState({
    chartoption: {},
    chartvalue: [],
  });

  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    var currentDate = new Date(props.dashboardDate);
    const chartmonth = [];
    var lastThreeMonths = [];

    for (var i = 0; i < 3; i++) {
      var month = currentDate.getMonth() - i;
      var year = currentDate.getFullYear();
      if (month < 0) {
        month += 12;
        year -= 1;
      }
      var date = new Date(year, month, 1);
      lastThreeMonths.push(
        date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2)
      );
      const newsdate = new Date(
        date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2)
      );
      chartmonth.push(
        `${monthNames[newsdate.getMonth()]} ${newsdate.getFullYear()}`
      );
    }

    var result_array = [];
    lastThreeMonths.forEach(function (month) {
      const input = {
        date_time: month,
      };

      dispatch(getLastThreeMonthCost(input))
        .unwrap()
        .then(({ data }) => {
          if (JSON.parse(data.daily_data).length > 0) {
            JSON.parse(data.daily_data).map((app_data) => {
              result_array.push({
                app_name: app_data.app_name,
                total_cost: app_data.total_cost,
                app_date: month,
                date_name_formate: `${
                  monthNames[new Date(month).getMonth()]
                } ${new Date(month).getFullYear()}`,
              });
            });
          }

          const applicationlist = result_array.map((v) => v.app_name);
          var application = new Set([...applicationlist]);
          application = [...application];
          var chart_records_formate = [];
          application.map((app_records) => {
            const chart_records_month = [];
            chartmonth.map((date_records) => {
              const application_spend = result_array.filter((app_datas) => {
                return (
                  app_datas.app_name === app_records &&
                  app_datas.date_name_formate === date_records
                );
              });
              if (application_spend.length > 0) {
                chart_records_month.push(
                  application_spend[0].total_cost
                    ? application_spend[0].total_cost
                    : 0
                );
              } else {
                chart_records_month.push(0);
              }
            });
            chart_records_formate.push({
              name: app_records,
              data: chart_records_month,
            });
          });

          setChart({
            chartoption: {
              chart: {
                type: "bar",
                height: "100px",
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  borderRadius: 0,
                },
              },
              stroke: {
                width: 1,
                colors: ["#fff"],
              },
              colors: [
                "#E3818A",
                "#BFBFE4",
                "#11104E",
                "#FCAF04",
                "#7CC4E6",
                "#CEE05C",
              ],
              xaxis: {
                categories: chartmonth,
                labels: {
                  formatter: (num) => {
                    return Math.abs(num) > 999
                      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                      : Math.sign(num) * Math.abs(num);
                  },
                  style: {
                    fontSize: "10px",
                    colors: "#ffffff",
                  },
                },
              },
              yaxis: {
                title: {
                  text: undefined,
                },
                labels: {
                  style: {
                    colors: "#fff",
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: (num) => {
                    return Math.abs(num) > 999
                      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                      : Math.sign(num) * Math.abs(num);
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                position: "bottom",
                labels: {
                  colors: "#ffffff",
                },
                horizontalAlign: "right",
                offsetX: 0,
                height: 40,
                markers: {
                  radius: 12,
                },
                itemMargin: {
                  horizontal: 0,
                  vertical: 0,
                },
              },
              title: {
                text: `Top 5 Application Cost`,
                align: "left",
                style: {
                  color: "#FFFFFF",
                  fontWeight: "lighter",
                },
              },
              responsive: [
                {
                  breakpoint: 450,
                  options: {
                    legend: {
                      position: "bottom",
                      horizontalAlign: "left",
                    },
                  },
                },
              ],
            },
            chartvalue: chart_records_formate,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [props]);

  return (
    <>
      <ReactApexChart
        options={chart.chartoption}
        series={chart.chartvalue}
        type="bar"
        height={250}
      />
    </>
  );
};

export default memo(PieChart);
