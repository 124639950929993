import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql,
  concat,
} from "@apollo/client";

const httpLink = new HttpLink({
  uri: "https://oao7vt5zpvb6pejtk6zq425o7y.appsync-api.ap-south-1.amazonaws.com/graphql",
  fetchOptions: {
    timeout: 60000, // Set the timeout value in milliseconds (adjust as needed)
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      //dev pro
      Authorization: localStorage.getItem("_token"),
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
