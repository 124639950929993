import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import SidemenuDesktop from "./SidemenuDesktop";
const Sidemenu = (props) => {
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  useEffect(() => {
    // setFullPath(window.location.pathname);
    if (window.location.pathname === "/apphub") {
      setSidebarMenu(1);
    } else if (window.location.pathname === "/report") {
      setSidebarMenu(2);
    } else if (window.location.pathname === "/price") {
      setSidebarMenu(3);
    } else if (window.location.pathname === "/credits") {
      setSidebarMenu(4);
    } else if (window.location.pathname === "/prompts") {
      setSidebarMenu(5);
    } else if (window.location.pathname === "/model") {
      setSidebarMenu(6);
    } else if (window.location.pathname === "/api") {
      setSidebarMenu(7);
    } else if (window.location.pathname === "/detail") {
      setSidebarMenu(8);
    }
  }, [window.location.pathname]);

  return (
    <div>
      <SidemenuDesktop sidebarmenu={sidebarmenu} />
    </div>
  );
};

export default memo(Sidemenu);
