import { Col, Row } from "antd";
import { memo } from "react";
import { simplifyNumber } from "../../customhook/CustomHoom";
const ModelCredits = (props) => {
  return (
    <>
      <Row>
        <Col span={10}>
          <h1 className="aivolvex-dashboard-model-token model-table-header">
            Model
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Count
          </h1>
        </Col>
        <Col span={5}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Tokens
          </h1>
        </Col>
        <Col span={5}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Share
          </h1>
        </Col>
      </Row>
      <div className="aivolvex-dashboard-modeltoken-scroll">
        {console.log(props)}
        {props.modallist.map((result) => {
          return (
            <Row className="mt-2">
              <Col span={8}>
                <label className="aivolvex-dashboard-model-token">
                  {result.model}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">
                  {simplifyNumber(result.count)}
                </label>
              </Col>
              <Col span={6} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">
                  {simplifyNumber(result.token)}
                </label>
              </Col>
              <Col span={6} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token">
                  {result.share}%{" "}
                </label>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
};

export default memo(ModelCredits);
