import { memo } from "react";
import Detailview from "./Detailview";
const Detailviewmain = () => {
  return (
    <>
      <div className="conversation-desktop">
        <Detailview />
      </div>

    </>
  );
};
export default memo(Detailviewmain);
