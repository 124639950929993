import { gql } from "@apollo/client";

const ONBOARD_AVATAR = gql`
  query MyQuery(
    $about: String!
    $avatar_title: String!
    $context: String!
    $pod_category: String!
    $avatar_image: String!
    $avatar_owner: String!
    $version: String!
  ) {
    aivolvex_control_plane(
      event_type: "new-avatar"
      company: "cmacgm"
      context: $context
      pod_type: "public"
      about_us: $about
      pod: $pod_category
      avatar_name: $avatar_title
      avatar_image: $avatar_image
      avatar_owner: $avatar_owner
      version: $version
    )
  }
`;

const LOGIN_EMAIL_CHECK = gql`
  query MyQuery($user_id: String!) {
    email_verification(event_type: "email-id-verification", email_id: $user_id)
  }
`;

const AZUREADEMAILCHECK = gql`
  query MyQuery($email: String!) {
    company_check(emailid: $email, event_type: "company_check")
  }
`;

const GENERATE_TABLE_CHART = gql`
  query MyQuery($chart_types: String!, $html_string: String!) {
    aivolvex_control_plane(
      event_type: "chart"
      html_string: $html_string
      chart_types: $chart_types
    )
  }
`;

const GET_CREDIT_MONTH = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    app_wise_question_count(
      user_name: $user_id
      date_time: $date_time
      event_type: "app_wise_question_count"
    )
  }
`;

const GET_PRICE_MONTH = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    price_monthwise(
      date_time: $date_time
      event_type: "price_monthwise"
      user_name: $user_id
    )
  }
`;

const GET_TOKEN_AGENT = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    token_month_wise(
      user_name: $user_id
      date_time: $date_time
      event_type: "token_monthwise"
    )
  }
`;
const API_GET_TOKEN_AGENT = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    api_token_monthwise(
      user_name: $user_id
      date_time: $date_time
      event_type: "api_token_monthwise"
    )
  }
`;

const GET_MODAL_CHART = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    model_wise_question_count(
      user_name: $user_id
      date_time: $date_time
      event_type: "model_wise_question_count"
    )
  }
`;

const API_GET_MODAL_CHART = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    api_model_wise_question_count(
      user_name: $user_id
      date_time: $date_time
      event_type: "api_model_wise_question_count"
    )
  }
`;

const APPHUB_CALCULATOR = gql`
  query MyQuery(
    $input_tokens: String!
    $model_name: String!
    $output_tokens: String!
    $provider: String!
    $api_type: String!
    $page: String!
    $input_character: String!
    $output_character: String!
  ) {
    token_calculator(
      event_type: "token_calculation"
      input_tokens: $input_tokens
      model_name: $model_name
      output_tokens: $output_tokens
      provider: $provider
      api_type: $api_type
      page: $page
      input_character: $input_character
      output_character: $output_character
    )
  }
`;
const GET_CREDIT_DAY = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    daywise_token(
      user_name: $user_id
      date_time: $date_time
      event_type: "daywise_token"
    )
  }
`;

const GET_PROMPT_DAY = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    daywise_question_token(
      user_name: $user_id
      date_time: $date_time
      event_type: "daywise_question_token"
    )
  }
`;

const GET_TOTAL_PROMPTS = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $month: String!
    $company: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      month: $month
      company: $company
      user_type: $user_type
      event_type: "question_count"
    )
  }
`;
const GET_TOP_USER = gql`
  query MyQuery($year: String!, $month: String!, $user_id: String!) {
    top_user_token(
      user_name: $user_id
      year: $year
      month: $month
      event_type: "top_user_token"
    )
  }
`;
const API_GET_TOP_USER = gql`
  query MyQuery($year: String!, $month: String!, $user_id: String!) {
    api_top_user_token(
      user_name: $user_id
      year: $year
      month: $month
      event_type: "api_top_user_token"
    )
  }
`;

const GET_USAGE_YEAR = gql`
  query MyQuery($user_id: String!, $year: String!) {
    year_total_count(
      user_name: $user_id
      year: $year
      event_type: "year_total_count"
    )
  }
`;

const API_GET_USAGE_YEAR = gql`
  query MyQuery($user_id: String!, $year: String!) {
    api_year_total_count(
      user_name: $user_id
      year: $year
      event_type: "api_year_total_count"
    )
  }
`;
const WELCOME_EMAIL = gql`
  query MyQuery($user_id: String!) {
    aivolvex_control_plane(user_id: $user_id, event_type: "welcome_email")
  }
`;

//apphub queries starts
const APPHUB_LIST_APPS = gql`
  query MyQuery(
    $drop_down: String!
    $user_id: String!
    $limit: String!
    $page: String!
    $text: String!
  ) {
    list_apps(
      drop_down: $drop_down
      email_id: $user_id
      event_type: "list_apps"
      limit: $limit
      page: $page
      text: $text
    )
  }
`;
const APPHUB_TOTAL_RECORDS = gql`
  query MyQuery($drop_down: String!, $user_id: String!, $text: String!) {
    app_pageno_recent(
      drop_down: $drop_down
      email_id: $user_id
      event_type: "app_pageno_recent"
      text: $text
    )
  }
`;
const APPHUB_INSERT_APPS = gql`
  query MyQuery(
    $app_name: String!
    $description: String!
    $model_name: String!
    $title: String!
    $email_id: String!
  ) {
    insert_app(
      app_name: $app_name
      description: $description
      event_type: "insert-app"
      model_name: $model_name
      title: $title
      email_id: $email_id
    )
  }
`;

const APPHUB_UPDATE_APPS = gql`
  query MyQuery(
    $app_name: String!
    $app_id: String!
    $description: String!
    $title: String!
    $model_name: String!
  ) {
    update_app(
      app_name: $app_name
      description: $description
      event_type: "update-app"
      app_id: $app_id
      model_name: $model_name
      title: $title
    )
  }
`;
const APPHUB_DELETE_APPS = gql`
  query MyQuery($app_id: String!) {
    delete_app(event_type: "delete_app", app_id: $app_id)
  }
`;

const APPHUB_RECORD_MODELS = gql`
  query MyQuery($app_id: String!) {
    list_model(event_type: "list_model", app_id: $app_id)
  }
`;
const APPHUB_APP_OPTIONS = gql`
  query MyQuery {
    list_model_providers(event_type: "list_model_providers")
  }
`;
//apphub queries ends

// report queries starts
const REPORT_TABLE_VALUES = gql`
  query MyQuery(
    $limit: String!
    $page: String!
    $drop_down: String!
    $text: String!
  ) {
    recent_prompt(
      event_type: "recent_prompt"
      limit: $limit
      page: $page
      drop_down: $drop_down
      text: $text
    )
  }
`;

const REPORT_TOTAL_PAGES = gql`
  query MyQuery($drop_down: String!, $text: String!) {
    pageno_recent(
      event_type: "pageno_recent"
      drop_down: $drop_down
      text: $text
    )
  }
`;

const GET_TOKEN_DETAILS = gql`
  query MyQuery($id: String!, $api_type: String!) {
    get_token_details(
      event_type: "get_token_details"
      id: $id
      api_type: $api_type
    )
  }
`;

// report queries ends

// cost queries starts

const PRICING_INFO = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    application_count(
      date_time: $date_time
      email_id: $user_id
      event_type: "application_count"
    )
  }
`;

const MODELWISE_PRICING = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    pie_chart(
      date_time: $date_time
      email_id: $user_id
      event_type: "pie_chart"
    )
  }
`;

const APP_PRICING_TABLE = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    table(event_type: "table", date_time: $date_time, email_id: $user_id)
  }
`;

const DAILY_PRICING = gql`
  query MyQuery($user_id: String!, $date_time: String!) {
    daily_data(
      date_time: $date_time
      event_type: "daily_data"
      email_id: $user_id
    )
  }
`;

const LAST_THREE_MONTH_COST = gql`
  query MyQuery($date_time: String!) {
    daily_data(date_time: $date_time, event_type: "top_model_cost")
  }
`;

// cost queries ends

const APPHUB_APP_TOKEN = gql`
  query MyQuery($date_time: String!, $api_type: String!) {
    list_token_details(
      date_time: $date_time
      api_type: $api_type
      event_type: "get_detailed_token_details"
    )
  }
`;
export {
  ONBOARD_AVATAR,
  LOGIN_EMAIL_CHECK,
  APPHUB_LIST_APPS,
  AZUREADEMAILCHECK,
  APPHUB_INSERT_APPS,
  APPHUB_UPDATE_APPS,
  GENERATE_TABLE_CHART,
  GET_PRICE_MONTH,
  GET_CREDIT_MONTH,
  GET_TOKEN_AGENT,
  API_GET_TOKEN_AGENT,
  GET_MODAL_CHART,
  API_GET_MODAL_CHART,
  GET_CREDIT_DAY,
  GET_PROMPT_DAY,
  GET_TOTAL_PROMPTS,
  GET_TOP_USER,
  API_GET_TOP_USER,
  GET_USAGE_YEAR,
  API_GET_USAGE_YEAR,
  WELCOME_EMAIL,
  APPHUB_DELETE_APPS,
  APPHUB_APP_OPTIONS,
  APPHUB_TOTAL_RECORDS,
  APPHUB_CALCULATOR,
  APPHUB_RECORD_MODELS,
  REPORT_TABLE_VALUES,
  REPORT_TOTAL_PAGES,
  GET_TOKEN_DETAILS,
  PRICING_INFO,
  MODELWISE_PRICING,
  APP_PRICING_TABLE,
  DAILY_PRICING,
  LAST_THREE_MONTH_COST,
  APPHUB_APP_TOKEN,
};
