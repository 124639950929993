import "./Myprofile.scss";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import navLogo from "../../assets/ui/Logo SVG.svg";
import { TbArrowBadgeDownFilled } from "react-icons/tb";

const SidemenuDesktop = (props) => {
  const navigateFn = useNavigate();
  const sidebarmenu = props.sidebarmenu;

  return (
    <>
      {/* Desktop side bar */}
      <Sidebar className="costimize-sidebar sidebar-desktop">
        <img
          src={navLogo}
          alt="nav-logo "
          className="navlogo cursor-pointer"
          onClick={() => {
            navigateFn("/conversation");
          }}
        />
        <Menu>
          <MenuItem
            className={sidebarmenu === 1 ? "active active-menu-item" : ""}
            component={<Link to="/apphub" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ph:cube-light"
                id="library"
                className={`DashboardNavicon ${
                  sidebarmenu === 1 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 1 ? "white-color" : ""
              }`}
            >
              Applications
            </div>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 2 ? "active active-menu-item" : ""}
            component={<Link to="/report" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="charm:square-tick"
                style={{ fontSize: "24px" }}
                className={`DashboardNavicon ${
                  sidebarmenu === 2 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 2 ? "white-color" : ""
              }`}
            >
              Report
            </div>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 3 ? "active active-menu-item" : ""}
            component={<Link to="/price" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="oi:dollar"
                className={`DashboardNavicon ${
                  sidebarmenu === 3 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 3 ? "white-color" : ""
              }`}
            >
              Cost
            </div>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 4 ? "active active-menu-item" : ""}
            component={<Link to="/credits" />}
          >
            <div className="nav-icon-sidebar">
              <TbArrowBadgeDownFilled
                className={`DashboardNavicon ${
                  sidebarmenu === 4 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 4 ? "white-color" : ""
              }`}
            >
              Tokens
            </div>
          </MenuItem>
          {/* <MenuItem
            className={sidebarmenu === 5 ? "active active-menu-item" : ""}
            component={<Link to="/prompts" />}
          >
            <div className="nav-icon-sidebar">
              {" "}
              <Icon
                icon="lets-icons:chat-alt-light"
                className={`DashboardNavicon ${
                  sidebarmenu === 5 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 5 ? "white-color" : ""
              }`}
            >
              Prompts
            </div>
          </MenuItem> */}
          {/* <MenuItem
            className={sidebarmenu === 6 ? "active active-menu-item" : ""}
            component={<Link to="/model" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="material-symbols:token-outline"
                className={`DashboardNavicon ${
                  sidebarmenu === 6 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 6 ? "white-color" : ""
              }`}
            >
              Model
            </div>
          </MenuItem> */}
          <MenuItem
            className={sidebarmenu === 7 ? "active active-menu-item" : ""}
            component={<Link to="/api" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="material-symbols:token-outline"
                className={`DashboardNavicon ${
                  sidebarmenu === 7 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 7 ? "white-color" : ""
              }`}
            >
              Model
            </div>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 8 ? "active active-menu-item" : ""}
            component={<Link to="/detail" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="icon-park-twotone:calendar-dot"
                className={`DashboardNavicon ${
                  sidebarmenu === 8 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 8 ? "white-color" : ""
              }`}
            >
              Usage Cost
            </div>
          </MenuItem>
          {/* <MenuItem
            className={sidebarmenu === 8 ? "active active-menu-item" : ""}
            component={<Link to="/users" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ci:users"
                className={`DashboardNavicon ${
                  sidebarmenu === 8 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 8 ? "white-color" : ""
              }`}
            >
              Top List
            </div>
          </MenuItem> */}
        </Menu>
      </Sidebar>
    </>
  );
};
export default SidemenuDesktop;
