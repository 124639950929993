import { Col, Row, Input, Button, Spin, Select, Avatar, Tag } from "antd";
import { memo, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import "./modal.scss";
import {
  modalconversation,
  allapplicationlist,
  allmodellist,
  tokendtailsgetvalue,
} from "../api/accoutActionmodal";
import navLogo from "../../assets/TO Logo White 1.png";
import logo from "../../assets/modelicon.svg";
const ModalBody = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [displayedText, setDisplayedText] = useState("");
  const [displayedansText, setDisplayedansText] = useState("");
  const [displayansbox, setDisplayedansbox] = useState(false);
  const [applicationlist, setApplicationList] = useState([]);
  const [modellist, setModelList] = useState([]);
  const [selectedmodel, setSelectedModel] = useState(null);
  const [tablestatus, setTablestatus] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  useEffect(() => {
    dispatch(allapplicationlist())
      .unwrap()
      .then(({ data }) => {
        const response = JSON.parse(data.app_model_list).application_list;
        const application_list = response?.map((data) => {
          return {
            value: data.id,
            label: data.application,
          };
        });
        setApplicationList(application_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const gettokendetails = (tokenid) => {
    const token_id = tokenid;
    dispatch(tokendtailsgetvalue(token_id))
      .unwrap()
      .then(({ data }) => {
        const response = JSON.parse(data.token_details);

        setTabledata({
          input_token: response.input_token,
          output_token: response.output_token,
          total_cost: response.total_cost,
          model_name: response.model_name,
          app_name: response.app_name,
        });
        setTablestatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEnterclick = () => {
    // const inputtext = text;
    const input = {
      model_id: selectedmodel,
      question: text,
    };

    setDisplayedText(text);
    setDisplayedansbox(true);
    setText("");
    dispatch(modalconversation(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.model_chat);

        setDisplayedansText(value.message);
        gettokendetails(value.response_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (app_id) => {
    setSelectedModel(null);
    dispatch(allmodellist(app_id))
      .unwrap()
      .then(({ data }) => {
        const response = JSON.parse(data.model_list).model_list;
        const model_list = response?.map((data) => {
          return {
            value: data.model_id,
            label: data.model_name,
          };
        });
        setModelList(model_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="modal-body">
        <Row gutter={[8, 8]}>
          <Col span={24} className="text-center">
            <h3 className="token-model-title">TokenOptix Model Demo</h3>
          </Col>
        </Row>
        <Row className="modal-input-field" gutter={[8, 8]}>
          <Col>
            <p className="modal-text-paragraph">
              <span className="modal-text-paragraph-title"> Note:</span> Welcome
              to TokenSim, your premier application simulator for TokenOptix!{" "}
            </p>{" "}
            <p className="modal-text-paragraph">
              TokenSim is an innovative demo application designed to replicate
              the functionality of your very own LLM-integrated application.
              With TokenSim, you have the power to select from a variety of
              providers and explore their offered services. Simply choose your
              preferred provider and model from our comprehensive list.
            </p>
            <p className="modal-text-paragraph">
              {" "}
              Once configured, you can input your questions into the designated
              window, and TokenSim will swiftly calculate and display an
              estimate of the associated costs based on the tokens utilized for
              each call. This allows you to gain valuable insights into the
              potential expenditure associated with integrating different models
              into your application.
            </p>
            <p className="modal-text-paragraph">
              {" "}
              For a more detailed analysis of your token usage and expenditure,
              You can view the drilled down details of each call made by your
              application under the "Records" section in the ToxenOptix
              Platform.
            </p>
          </Col>
        </Row>
        {tablestatus && (
          <>
            <Row className="modal-input-field" gutter={[8, 8]}>
              <Col span={24}>
                <table className="demo-model-table">
                  <thead>
                    <tr>
                      {/* <th>Application</th>
                      <th>Model</th>
                      <th>Input</th>
                      <th>Output</th>
                      <th>Price</th> */}
                      <th className="model-table-border">Application</th>
                      <th className="model-table-border">Model</th>
                      <th className="model-table-border">Input</th>
                      <th className="model-table-border">Output</th>
                      <th className="model-table-border-leftside">Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="model-table-border">
                        {tabledata.app_name}
                      </td>
                      <td className="model-table-border">
                        {tabledata.model_name}
                      </td>
                      <td className="model-table-border">
                        <Tag
                          color="#53343e"
                          className="model-input-tag"
                          icon={<img src={logo} className="navlogo-model " />}
                        >
                          <span>
                            {" "}
                            {""} {tabledata.input_token}
                          </span>
                        </Tag>
                      </td>
                      <td className="model-table-border">
                        <Tag
                          color="#564a31"
                          className="model-output-tag"
                          icon={<img src={logo} className="navlogo-model " />}
                        >
                          {""}
                          {tabledata.output_token}
                        </Tag>
                      </td>
                      <td className="model-table-border-leftside">
                        ${tabledata.total_cost}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </>
        )}
        <Row className="modal-input-field" gutter={[8, 8]}>
          <Col xs={12} sm={12} md={12} xl={3} xxl={3} className="pl-2">
            <Select
              className="demo-model-select"
              placeholder="Select Application"
              onChange={handleChange}
              options={applicationlist}
            />
          </Col>
          <Col xs={12} sm={12} md={12} xl={3} xxl={3} className="pl-2">
            <Select
              className="demo-model-select"
              placeholder="Select Model"
              onChange={(model_id) => {
                setSelectedModel(model_id);
              }}
              value={selectedmodel}
              options={modellist}
            />
          </Col>
          <Col xs={20} sm={22} md={22} xl={17} xxl={17} className="pl-2">
            <Input.TextArea
              autoSize={{ minRows: 1 }}
              className="modal-input-bg"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </Col>
          <Col xs={4} sm={2} md={2} xl={1} xxl={1}>
            <Button
              className="modal-input-icon-bg"
              onClick={() => handleEnterclick()}
            >
              <Icon icon="ic:baseline-send" className="modal-icon" />
            </Button>
          </Col>
        </Row>
        {displayedText && (
          <Row className="modal-question-box" gutter={[24, 24]}>
            <Col className="modal-question">
              <p>{displayedText}</p>
            </Col>
            <Col >
              {" "}
              <Avatar size="large" icon={<Icon icon="mdi:user" />} />
            </Col>
          </Row>
        )}
        {displayansbox && (
          <Row className="modal-answer-box" gutter={[24, 24]}>
            <Col xs={4} sm={2} md={2} xl={1} xxl={1}>
              {" "}
              <Avatar
                size="large"
                icon={
                  <img
                    src={navLogo}
                    alt="nav-logo "
                    className="navlogo-model cursor-pointer"
                  />
                }
              />
            </Col>
            <Col xs={20} sm={22} md={22} xl={22} xxl={22}>
              <div className="modal-answer">
                {displayedansText ? (
                  <>
                    {" "}
                    <p>{displayedansText}</p>{" "}
                  </>
                ) : (
                  <>
                    <div className="modal-spinner">
                      <Spin size="large" tip="Loading" />
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default ModalBody;
