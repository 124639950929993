import React, { useState, useEffect } from "react";
import { Col, Row, Popconfirm, DatePicker } from "antd";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import preloader from "../../../assets/loader/pre_loader.gif";
import Sidemenu from "../../Sidemenu/Sidemenu";
import NavBar from "../../Header/NavBar";
import {
  apppricingtable,
  dailypricing,
  getpricemonth,
  modelwisepricing,
  pricinginfo,
  princinginfo,
} from "../../api/accountAction";
import AppPrice from "./AppPrice";
import PieChart from "./PieChart";
function Pricing() {
  const [dashboardDate, setDashboardDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [priceSeries, setPriceSeries] = useState([]);
  const [priceLabels, setPriceLabels] = useState([]);
  const [modelSeries, setModelSeries] = useState([]);
  const [modelLabels, setModelLabels] = useState([]);
  const [pricingInfo, setPricingInfo] = useState({
    cost_for_month: 0,
    app_count: 0,
    model_count: 0,
    predictive_result: 0,
  });
  const [labelday, setlabelday] = useState([]);
  const [appTableTotal, setAppTableTotal] = useState([]);
  const [appTable, setAppTable] = useState([]);
  const [barseries, setBarSeries] = useState([]);
  const date = new Date(dashboardDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    value
      ? setDashboardDate(value.format(dateFormat))
      : setDashboardDate(
          `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`
        );
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const monthFormat = "YYYY/MM";
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[date.getMonth()];

  const dispatch = useDispatch();
  useEffect(() => {
    const input = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(getpricemonth(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.price_monthwise);
        var series = [];
        var labels = [];
        value.map((items) => {
          series.push(items.total_cost ?? 0);
          labels.push(items.app_name);
        });
        setPriceLabels(labels);
        setPriceSeries(series);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    setPreloaderStatus(true);
    dispatch(modelwisepricing(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.pie_chart);
        var series = [];
        var labels = [];
        value.map((items) => {
          series.push(items.cost ?? 0);
          labels.push(items.model_name);
        });
        setModelLabels(labels);
        setModelSeries(series);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    dispatch(pricinginfo(input))
      .unwrap()
      .then(({ data }) => {
        console.log(data);

        const value = JSON.parse(data.application_count)[0];
        setPricingInfo(value ?? {});
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    dispatch(apppricingtable(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.table);
        let totalCost = 0;
        value.map((item) => {
          totalCost += item.total_cost_count;
        });
        setAppTable(value);
        setAppTableTotal(totalCost);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    setPreloaderStatus(true);
    dispatch(dailypricing(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.daily_data).data;
        if (value) {
          const dates = value.map((item) => item.date_time);
          const numdates = new Date(date.getYear(), date.getMonth() + 1, 0);
          const allDates = Array.from({ length: numdates.getDate() }, (_, i) =>
            String(i + 1).padStart(2, "0")
          );
          console.log(allDates);
          setlabelday(allDates);
          let result = {};
          value.map((item) => {
            const tempname = item.app_name;
            if (!Object.keys(result).includes(tempname)) {
              result[tempname] = Array(numdates.getDate()).fill(0);
            }
            result[tempname][parseInt(item.date_time.slice(-2), 10) - 1] =
              parseFloat(item.total_cost);
          });
          const series = [];
          for (const key in result) {
            series.push({
              name: key,
              data: result[key],
            });
          }
          console.log(series);
          setBarSeries(series);
          // setlabelday(allDates);
          // setNumbericday(result);
        } else {
          console.log("No data found.");
          setPreloaderStatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
  }, [dashboardDate]);

  const options_price = {
    chart: {
      type: "pie",
    },
    labels: priceLabels,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      show: true,
      height: 40,
      labels: {
        colors: "#FFFFFF",
      },
      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
          // legend: false,
        },
      },
    ],
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Application Wise Cost`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };
  const options_model = {
    chart: {
      type: "pie",
    },
    labels: modelLabels,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      show: true,
      labels: {
        colors: "#FFFFFF",
      },
      height: 40,
      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "right",
          },
          // legend: false,
        },
      },
    ],
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Model Wise Cost`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };
  const options = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: "Daywise Application Cost",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    xaxis: {
      categories: labelday,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          colors: "#ffffff",
        },
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const date = new Date(dashboardDate);
          const day = date.getDate();
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${num}/${month}`;
          return current_month_text;
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    fill: {
      colors: [
        "#7E2553",
        "#7149C6",
        "#FE6244",
        "#FFDEB9",
        "#F50076",
        "#00CCF5",
        "#FF7700",
        "#FFCF00",
        "#0AFFC2",
        "#006EE9",
        "#1D2B53",
        "#512E5E",
        "#FFF024",
        "#FF004D",
      ],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      labels: {
        colors: "#FFFFFF",
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
  

  return (
    <>
      {/* <NavBar /> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <NavBar />
          <div className="body-margin-top ">
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-left"
              >
                <h4 className="dashboard-credit aivolvex-font">Cost</h4>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-end"
              >
                <DatePicker
                  className="dashboard-datepicker"
                  defaultValue={dayjs(dashboardDate, monthFormat)}
                  format={monthFormat}
                  picker="month"
                  onChange={handleMonthChange}
                  disabledDate={disabledDate}
                />
              </Col>
            </Row>
            {/* <Row span={24}>
        <Col span={24}>
          <ModalUsageTrend />
        </Col>
      </Row> */}

            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="aivolvex-dashboard-outline full-width height-100 ">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow height-100 ">
                    <div
                      className="Dashboard_head_content"
                      // style={{ position: "relative" }}
                    >
                      <div id="chart" className="align-center-div">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="40%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <div className="public-agent-desktop">
                              <div className="pricing-info-heading">
                                Usage for the month
                              </div>
                              <div className="pricing-info-value">
                                $
                                {pricingInfo.cost_for_month
                                  ? pricingInfo.cost_for_month.toFixed(4)
                                  : 0}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={19} lg={19} xl={19}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div
                      className="Dashboard_head_content"
                      // style={{ position: "relative" }}
                    >
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="65%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <div className="public-agent-desktop">
                              <AppPrice
                                applist={appTable}
                                total={appTableTotal}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="aivolvex-dashboard-outline full-width height-100">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow height-100">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="65%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={options_price}
                              series={priceSeries}
                              type="pie"
                              height={300}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="aivolvex-dashboard-outline full-width height-100">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow height-100">
                    <div
                      className="Dashboard_head_content"
                      // style={{ position: "relative" }}
                    >
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="65%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <div className="public-agent-desktop">
                              <div className="public-agent-desktop">
                                <ReactApexChart
                                  options={options_model}
                                  series={modelSeries}
                                  type="pie"
                                  height={300}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="aivolvex-dashboard-outline full-width height-100">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow height-100">
                    <div
                      className="Dashboard_head_content"
                      // style={{ position: "relative" }}
                    >
                      <div id="chart">
                        {/* {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="65%" />
                              </div>
                            </Col>
                          </>
                        ) : ( */}
                        <>
                          <div className="public-agent-desktop">
                            <div className="public-agent-desktop">
                              <PieChart dashboardDate={dashboardDate} />
                            </div>
                          </div>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16, 16, 16]}
            >
              <Col span={24}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="25%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={options}
                              series={barseries}
                              type="bar"
                              height={350}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Pricing;
