import { createAsyncThunk } from "@reduxjs/toolkit";
import new_without_client from "./new_without_client";
import {
  START_MODAL_CONVERSATION,
  ALL_APPLICATION_LIST,
  ALL_MODEL_LIST,
  TOKEN_DETAILS_LIST,
} from "./accountquerymodal";
// for new chat
export const modalconversation = createAsyncThunk(
  "modal/solution",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const value = await new_without_client.mutate({
        mutation: START_MODAL_CONVERSATION,
        variables: {
          question: data.question,
          model_id: data.model_id,
        },
      });
      return value;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const allapplicationlist = createAsyncThunk(
  "modal/solution",
  async (data, { rejectWithValue }) => {
    try {
      const value = await new_without_client.mutate({
        mutation: ALL_APPLICATION_LIST,
        variables: {},
      });
      return value;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const allmodellist = createAsyncThunk(
  "modal/solution",
  async (data, { rejectWithValue }) => {
    try {
      const value = await new_without_client.mutate({
        mutation: ALL_MODEL_LIST,
        variables: {
          app_id: data,
        },
      });
      return value;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const tokendtailsgetvalue = createAsyncThunk(
  "modal/table",
  async (data, { rejectWithValue }) => {
    try {
      const value = await new_without_client.mutate({
        mutation: TOKEN_DETAILS_LIST,
        variables: {
          token_id: data,
        },
      });
      return value;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
