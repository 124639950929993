import React, { useEffect, useState } from "react";
import { Table, Row, Col, DatePicker, Tag, Select } from "antd";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import preloader from "../../assets/loader/pre_loader.gif";
import { tokenlist } from "../api/accountAction";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import "./detail.css";
import { Icon } from "@iconify/react";
const Detailview = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("all");
  const [dashboardDate, setDashboardDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const dispatch = useDispatch();
  const date = new Date(dashboardDate);
  const monthFormat = "YYYY/MM";
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    value
      ? setDashboardDate(value.format(dateFormat))
      : setDashboardDate(
          `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`
        );
  };
  //   console.log(data);
  useEffect(() => {
    const input = {
      date_time: dashboardDate.toString(),
      api_type: selectedItem,
    };
    // console.log(input);
    dispatch(tokenlist(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.list_token_details);
        console.log(value);
        const groupedData = value.response.reduce(
          (acc, { app_name, api_type, api_cost, total_cost, model_name }) => {
            if (!acc[app_name]) {
              acc[app_name] = {
                key: app_name,
                app_name: app_name,
                apitypes: [],
                model_names: [],
                totalCost: 0,
              };
            }
            acc[app_name].apitypes.push({
              api_type,
              model_name,
              cost: api_cost > 0 ? api_cost : total_cost,
            });
            acc[app_name].totalCost += api_cost + total_cost;
            return acc;
          },
          {}
        );
        // console.log(groupedData);
        const transformedData = Object.values(groupedData);
        setData(transformedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dashboardDate, selectedItem]);
  const apiTypeColors = {
    llm: "cyan",
    page: "green",
    audio: "yellow",
    character: "purple",
    default: "gray",
  };
  const columns = [
    {
      title: "App Name",
      dataIndex: "app_name",
      key: "app_name",
    },

    {
      title: "API Type",
      dataIndex: "apitypes",
      key: "apitype",
      align: "center",
      render: (apitypes) => (
        <div className="p-1">
          {apitypes.map(({ api_type }, index) => (
            <>
              {" "}
              <div className="p-1">
                <Tag
                  key={index}
                  color={apiTypeColors[api_type] || apiTypeColors.default}
                >
                  {api_type}
                </Tag>
                <br />
              </div>
            </>
          ))}
        </div>
      ),
    },
    {
      title: "Model Name",
      dataIndex: "apitypes",
      key: "model_name",
      render: (apitypes) => (
        <div className="p-1">
          {apitypes.map(({ model_name, api_type }, index) => (
            <div key={index} className="p-1">
              <Icon
                color={apiTypeColors[api_type] || apiTypeColors.default}
                icon="ph:dot-fill"
                className="dot-icon"
              />{" "}
              {model_name}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Cost ($)",
      dataIndex: "apitypes",
      key: "cost",
      render: (apitypes) => (
        <div>
          {apitypes.map(({ cost, api_type }, index) => (
            <div key={index} className="p-1">
              {" "}
              <Icon
                color={apiTypeColors[api_type] || apiTypeColors.default}
                icon="ph:dot-fill"
                className="dot-icon"
              />
              {cost.toFixed(4)}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Total Cost ($)",
      dataIndex: "totalCost",
      key: "totalCost",
      align: "right",
      render: (record) => <div>{record.toFixed(4)}</div>,
    },
  ];
  const items = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "LLM",
      value: "llm",
    },
    {
      label: "Page",
      value: "page",
    },
    {
      label: "Audio",
      value: "audio",
    },
    {
      label: "Character",
      value: "character",
    },
  ];
  const handleItemClick = (item) => {
    setSelectedItem(item);
    console.log(item);
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={3} xl={3}>
        <Sidemenu />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={21}
        xl={21}
        className="aivolvex-dashboard-body"
      >
        <NavBar />
        <div className="body-margin-top">
          <div className="dashboard-table-body">
            <Col span={24} className=" recent-body">
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={18}
                  xl={18}
                  className="d-flex justify-content-left"
                >
                  <h4 className="dashboard-credit aivolvex-font">Usage Cost</h4>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={2}
                  xl={2}
                  className="d-flex justify-content-end"
                >
                  {" "}
                  <Select
                    defaultValue={selectedItem}
                    className="recent-search-dropdown"
                    onChange={handleItemClick}
                    options={items}
                    style={{ width: 120 }}
                  />{" "}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={4}
                  xl={4}
                  className="d-flex justify-content-end"
                >
                  <DatePicker
                    className="dashboard-datepicker"
                    defaultValue={dayjs(dashboardDate, monthFormat)}
                    format={monthFormat}
                    picker="month"
                    onChange={handleMonthChange}
                    disabledDate={disabledDate}
                  />
                </Col>
              </Row>

              <Row>
                {preloaderstatus ? (
                  <>
                    <Col span={24}>
                      <div className="aivolvex-sub-loader h-18">
                        <img src={preloader} width="20%" />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={24} className="recent-prompt-table">
                      <Table
                        columns={columns}
                        className="recent-prompts-table"
                        dataSource={data}
                        pagination={false}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Detailview;
