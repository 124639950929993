import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql,
  concat,
} from "@apollo/client";
// for new chat
const httpLink = new HttpLink({
  uri: "https://vr3u7zs4wvgrpgdqx3smxepyhi.appsync-api.ap-south-1.amazonaws.com/graphql",
  fetchOptions: {
    timeout: 60000,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-api-key": "da2-df5mlzez2bcoxazzklcvt24r6y",
    },
  }));

  return forward(operation);
});

const new_without_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default new_without_client;
