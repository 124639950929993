import { memo } from "react";
import "./login.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import SignInBanner from "../../assets/banner/SignInBanner.jpg";
import {
  loginEmailcheck,
  azureAdEmailCheck,
} from "../../components/api/accountAction";

import { useDispatch } from "react-redux";
import { Button, Row, Col, message, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/ui/Logo SVG.svg";
import {
  cognitoSignIn,
  forgotPassword,
  submitForgotPassword,
  getCorrentSession,
} from "../Amplify/auth-config";

const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [signinstatus, setSigninstatus] = useState(false);
  const [signupstatus, setSignupstatus] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openforgotpassword, setOpenForgotPassword] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");
  const [formforgotstatus, setFormForgotStatus] = useState(true);
  const [azureaderrormessage, setAzureErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMobileSignin, setShowMobileSignin] = useState(false);
  const [hasAccount, setHasAccount] = useState(true);

  // Function to update the state when the element is clicked
  const toggleVisibility = () => {
    setHasAccount(!hasAccount);
  };
  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };
  const handleSignin = () => {
    // setSigninstatus(true)
    setButtonLoader(false);
    setShowMobileSignin(true);
  };

  const handleSignup = () => {
    navigate("./signup");
    // setSignupstatus(true)
  };
  const onFinishAzureAd = (values) => {
    setIsSubmitting(true);
    // console.log(values);
    let email = values.email;

    dispatch(azureAdEmailCheck(email))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        const records = JSON.parse(data.company_check);
        // console.log(records);
        if (records.error) {
          setAzureErrorStatus(true);
          setAzureErrorMessage(records.error);
        } else {
          // console.log(records);
          const client_id = records.azureclient_id;
          const tenantid = records.tenant_id;
          const cognito_client_id = records.cognitoclient_id;
          // console.log(
          //   "https://launcher.myapps.microsoft.com/api/signin/" +
          //     client_id +
          //     "?tenantId=" +
          //     tenantid
          // );
          window.location.href =
            "https://launcher.myapps.microsoft.com/api/signin/" +
            client_id +
            "?tenantId=" +
            tenantid;
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsSubmitting(false);
        setAzureErrorStatus(true);
      });
  };
  const emailidVerification = () => {
    setIsSubmitting(true);
    setButtonLoader(true);
    var input = {
      user_id: email,
      password: password,
    };
    var new_email = email;

    dispatch(cognitoSignIn(input))
      .unwrap()
      .then((signindata) => {
        // console.log(signindata);
        localStorage.setItem(
          "_token",
          signindata.signInUserSession.accessToken.jwtToken
        );
        dispatch(loginEmailcheck(input))
          .unwrap()
          .then(({ data }) => {
            console.log(data.email_verification);
            setIsSubmitting(false);
            const encodedString = btoa(
              JSON.parse(data.email_verification)[0].user_name
            );
            const user_details = JSON.parse(data.email_verification)[0];
            const encodedEmail = btoa(new_email);
            const normalencodedString = btoa("normal");
            const store_user_details = btoa(
              user_details.first_name ? user_details.first_name : new_email
            );
            localStorage.setItem(
              "TokenOptixIdentityServiceProvider.usr",
              encodedString
            );
            localStorage.setItem(
              "TokenOptixIdentityServiceProvider.logtype",
              normalencodedString
            );
            localStorage.setItem(
              "TokenOptixIdentityServiceProvider.store",
              store_user_details
            );
            localStorage.setItem(
              "TokenOptixIdentityServiceProvider.auth",
              encodedEmail
            );
            window.location.href = "/apphub";
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log(err.message);
            // });
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        message.error(err);
      });
  };
  const [form] = Form.useForm();
  const onFinishForgotPasswordOTP = (val) => {
    const input = {
      emailid: val.email,
    };
    setForgotEmail(val.email);
    dispatch(forgotPassword(input))
      .unwrap()
      .then((data) => {
        setFormForgotStatus(false);
        message.success("Verification Code Sent Your Mail ID");
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords do not match!");
    },
  });

  const onFinishForgotPasswordSubmit = (val) => {
    const input = {
      emailid: forgotemail,
      otpvalue: val.verification_code,
      newpassword: val.password,
    };
    dispatch(submitForgotPassword(input))
      .unwrap()
      .then((data) => {
        message.success("Password changed successfully done");
        setOpenForgotPassword(false);
        setFormForgotStatus(true);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  return (
    <div className="login-main">
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {/* <LoginBanner /> */}
          <img src={SignInBanner} className="login-banner"></img>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="login-content">
          <div className="login-container">
            <div>
              {/* <h2 className="login-font">SEAMLESS</h2>
              <h2 className="login-font">SUSTAINABLE</h2>
              <h2 className="login-font">SUPERIOR</h2>     */}
              <h2 className="login-font">EFFICIENT</h2>
              <h2 className="login-font">ENLIGHTENING</h2>
              <h2 className="login-font">ECONOMICAL</h2>
            </div>
            <img src={logo} className="login-logo" />
          </div>
        </Col>
      </Row>
      <div className="login-modal-container">
        <div className="login-modal">
          {hasAccount ? (
            <div className="login-modal-body">
              <h2 className="login-modal-header">
                Hey, <strong>Welcome Back!</strong>
              </h2>
              <Form
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSignin}
              >
                <Form.Item name="username" label="Email"></Form.Item>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Username"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="password" label="Password"></Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item className="d-flex justify-content-end">
                  <a
                    className="login-form-forgot"
                    onClick={() => {
                      setOpenForgotPassword(true);
                      setFormForgotStatus(true);
                      const records = {
                        email: "",
                      };
                      form.setFieldsValue(records);
                    }}
                  >
                    Forgot password ?
                  </a>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    onClick={emailidVerification}
                    loading={isSubmitting}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex">
          {/* Display the element based on the state */}
          {hasAccount ? (
            <div>
              {/* <p className="login-message">
                Don't have an account yet? &nbsp;
                <strong
                  onClick={toggleVisibility}>
                  Sign Up
                </strong>
              </p> */}
            </div>
          ) : (
            <div>
              <p className="login-message">
                Already have an account? &nbsp;
                <strong onClick={toggleVisibility}>Sign In</strong>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* <img src={tagline} className="login-tagline-logo" /> */}
    </div>
  );
};

export default memo(Login);
