import { gql } from "@apollo/client";
// for new chat
const START_MODAL_CONVERSATION = gql`
  query MyQuery($question: String!, $model_id: String!) {
    model_chat(question: $question, model_id: $model_id)
  }
`;

const ALL_APPLICATION_LIST = gql`
  query MyQuery {
    app_model_list(
      user_id: "vijayakumar.r@1cloudhub.com"
      event_type: "application_list"
    )
  }
`;

const ALL_MODEL_LIST = gql`
  query MyQuery($app_id: String!) {
    model_list(
      app_id: $app_id
      event_type: "model_list"
      user_id: "vijayakumar.r@1cloudhub.com"
    )
  }
`;
const TOKEN_DETAILS_LIST = gql`
  query MyQuery($token_id: String!) {
    token_details(event_type: "token_details", token_id: $token_id)
  }
`;

export {
  START_MODAL_CONVERSATION,
  ALL_APPLICATION_LIST,
  ALL_MODEL_LIST,
  TOKEN_DETAILS_LIST,
};
