import { Col, Row } from "antd";
import { memo } from "react";
import { simplifyNumber } from "../../customhook/CustomHoom";
const ApiModelCredits = (props) => {
  return (
    <>
      <Row>
        <Col span={8}>
          <h1 className="aivolvex-dashboard-model-token model-table-header">
            Model
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Count
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Pages
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Char
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Share
          </h1>
        </Col>
      </Row>
      <div className="aivolvex-dashboard-modeltoken-scroll">
        {console.log(props.modallist)}
        {props.modallist.map((result) => {
          return (
            <Row className="mt-2">
              <Col span={8}>
                <label className="aivolvex-dashboard-model-token d-flex ">
                  {result.model}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.call_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.page_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.char_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {result.share}%{" "}
                </label>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
};

export default memo(ApiModelCredits);
