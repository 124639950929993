import { memo, useEffect, useState } from "react";
import React from "react";
import NavBar from "../../Header/NavBar";
import Curldetails from "./Curldetails";
import preloader from "../../../assets/loader/pre_loader.gif";
import {
  Col,
  DatePicker,
  Popconfirm,
  Row,
  Space,
  Table,
  Modal,
  Form,
  Input,
  Select,
  message,
  InputNumber,
} from "antd";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { formatDatewithtime } from "../../customhook/CustomHoom";
import {
  apphublistapps,
  apphubinsertapps,
  apphubupdateapps,
  apphubdeleteapps,
  apphubappoptions,
  apphubrecordmodels,
  apphubtotalrecords,
} from "../../api/accountAction";
import { Button } from "antd";
import TokenCalculator from "./TokenCalculator";
const { TextArea } = Input;
const DashboardDesktop = () => {
  // table pagination
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState("");
  const [record, setRecord] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("app_name");

  // modals
  const [inputModal, setInputModal] = useState(false);
  const [openRecordModels, setOpenRecordModels] = useState(false);
  const [openCalcModal, setOpenCalcModal] = useState(false);
  const [openCurlModal, setOpenCurlModal] = useState(false);

  // flags
  const [listupdate, setlistUpdate] = useState(false);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [editstatus, seteditstatus] = useState(false);
  const [editurlstatus, setediturlstatus] = useState(false);

  // data
  const [editrecord, seteditrecord] = useState([]);
  const [recordModels, setRecordModels] = useState([]);
  const [curlData, setCurlData] = useState();
  const [modelOptions, setModelOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [providerModelMap, setProviderModelMap] = useState({});
  const [selectedModels, setSelectedModels] = useState([]);
  const [modelNameId, setmodelNameId] = useState([]);

  const companyName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.usr")
  );
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // common cancel function
  const handleCancel = () => {
    setInputModal(false);
    setOpenCurlModal(false);
    setOpenCalcModal(false);
    setOpenRecordModels(false);
    seteditstatus(false);
    setediturlstatus(false);
    setmodelNameId({});
    setSelectedModels([]);
    setRecordModels([]);
    setCurlData([]);
    setmodelNameId([]);
    setModelOptions([]);
    form.setFieldsValue({ ["model_name"]: [] });
    form.setFieldsValue({ ["provider_name"]: [] });
  };

  const handleInsertApp = () => {
    var records = {
      app_name: "",
      description: "",
      provider_name: undefined,
      model_name: undefined,
      title: "",
      email_id: "",
    };
    form.setFieldsValue(records);
    seteditstatus(false);
    setInputModal(true);
  };

  const handleEditApp = (record) => {
    var records = {
      app_name: "",
      description: "",
      title: "",
      email_id: "",
    };
    if (record) {
      dispatch(apphubrecordmodels(record.id.toString()))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.list_model);
          const result = value.map(
            (obj) => `${obj.provider},,,${obj.model_name}`
          );
          const options = [];
          var tempnameId = {};
          value.map((obj) => {
            tempnameId[`${obj.provider},,,${obj.model_name}`] = obj.convo_id;
            if (!options.includes(obj.provider)) {
              options.push(obj.provider);
            }
          });
          setmodelNameId(tempnameId);
          const defaultmodels = [];
          options.map((obj) => defaultmodels.push(...providerModelMap[obj]));
          setModelOptions(defaultmodels);
          form.setFieldsValue({ ["provider_name"]: options });
          form.setFieldsValue({ ["model_name"]: result });
          setSelectedModels(
            result.map((obj) => ({
              ["value"]: obj,
              ["label"]: obj.split(",,,")[1],
            }))
          );
        })
        .catch((err) => {
          form.setFieldsValue({ ["provider_name"]: [] });
          form.setFieldsValue({ ["model_name"]: [] });
          setSelectedModels([]);
          console.log(err);
        });
      records = {
        app_name: record.app,
        description: record.description,
        title: record.title,
        email_id: record.email,
      };
    }
    form.setFieldsValue(records);
    seteditrecord(record);
    seteditstatus(true);
    setInputModal(true);
  };

  const handleItemClick = (item) => {
    // console.log(item);
    setSelectedItem(item);
  };

  const onFinish = (values) => {
    const app_id = editrecord.id;
    const email_id = userName;
    if (editstatus) {
      const models = values.model_name.map((obj) => ({
        ["model_id"]: modelNameId[obj] ? modelNameId[obj] : "",
        ["model_name"]: obj.split(",,,")[1],
        ["provider"]: obj.split(",,,")[0],
      }));
      values.model_name = JSON.stringify(models);
      values.title = "";
      dispatch(apphubupdateapps({ values, app_id }))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.update_app);
          setInputModal(false);
          setlistUpdate(!listupdate);
          message.success("Record Updated");
          setmodelNameId({});
        })
        .catch((err) => {
          console.log(err);
          setInputModal(false);
          setmodelNameId({});
        });
    } else {
      const models = values.model_name.map((obj) => ({
        ["model_name"]: obj.split(",,,")[1],
        ["provider"]: obj.split(",,,")[0],
      }));
      values.model_name = JSON.stringify(models);
      values.title = "";
      dispatch(apphubinsertapps({ values, email_id }))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.insert_app);
          if (value[0].body) {
            message.error(value[0].body);
            setInputModal(false);
          } else {
            setCurlData(value);
            setOpenCurlModal(true);
            setlistUpdate(!listupdate);
            message.success("Record Inserted");
            setInputModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setInputModal(false);
        });
    }
  };

  //delete records
  const handledeleteRecord = (record) => {
    seteditstatus(false);
    const id = record.id;
    dispatch(apphubdeleteapps(id.toString()))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.delete_app);
        setlistUpdate(!listupdate);
        message.success("Record Deleted");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handle modals
  const handleOpenCurl = (record) => {
    dispatch(apphubrecordmodels(record.id.toString()))
      .unwrap()
      .then(({ data }) => {
        setCurlData(JSON.parse(data.list_model));
      })
      .catch((err) => {
        console.log(err);
      });

    setediturlstatus(true);
    setOpenCurlModal(true);
  };

  const handleOpenCalcModal = (record) => {
    dispatch(apphubrecordmodels(record.id.toString()))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.list_model).map((obj) => ({
          ["value"]: `${obj.provider},,,${obj.model_name},,,${obj.model_type}`,
          ["label"]: obj.model_name,
        }));
        setSelectedModels(value);
        setOpenCalcModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSelectedModels(modelOptions);
        setOpenCalcModal(true);
      });
  };

  const handleRecordModels = (record) => {
    dispatch(apphubrecordmodels(record.id.toString()))
      .unwrap()
      .then(({ data }) => {
        setRecordModels(JSON.parse(data.list_model));
      })
      .catch((err) => {
        console.log(err);
      });

    setOpenRecordModels(true);
  };

  //data columns
  const columns = [
    {
      title: "Date and Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      // width: "15%",
      align: "center",
      render: (text) => formatDatewithtime(text),
    },
    {
      title: "App Name",
      dataIndex: "app",
      key: "app",
      // width: "15%",
      align: "center",
    },
    // {
    //   title: "Title",
    //   dataIndex: "title",
    //   key: "title",
    //   // width: "15%",
    //   align: "center",
    // },
    {
      title: "Model List",
      dataIndex: "model",
      key: "model",
      // width: "10%",
      render: (text, record) => (
        <div className="description-popup">
          <Icon
            icon="material-symbols:token-outline"
            className="cursor-pointer description-popup-icon"
            onClick={() => {
              handleRecordModels(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: "10%",
      align: "center",
      render: (description, record) => {
        return (
          <>
            {description && (
              <div className="description-popup">
                <Popconfirm
                  className="description-popup"
                  placement="top"
                  title=""
                  description={description}
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <Icon
                    icon="fluent:notepad-edit-20-regular"
                    className="cursor-pointer description-popup-icon"
                  />
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   // width: "15%",
    //   align: "center",
    // },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   width: "15%",
    //   align: "center",
    // },

    // {
    //   title: "Input Token",
    //   dataIndex: "itoken",
    //   key: "itoken",
    //   align: "center",
    // },
    // {
    //   title: "Output Token",
    //   dataIndex: "otoken",
    //   key: "otoken",
    //   align: "center",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      // width: "15%",
      render: (text, record, index) => (
        <>
          <div className="d-flex justify-content-around">
            <span>
              <Icon
                icon="mi:edit"
                className="edit-icon cursor-pointer "
                onClick={() => handleEditApp(record)}
              />
            </span>
            <span>
              <Icon
                icon="tdesign:code"
                className="edit-icon cursor-pointer "
                onClick={() => handleOpenCurl(record)}
              />
            </span>
            <span>
              <Popconfirm
                description="Are you sure to delete this record?"
                onConfirm={() => handledeleteRecord(record)}
              >
                <Icon
                  icon="material-symbols:delete-outline"
                  className="edit-icon cursor-pointer "
                />
              </Popconfirm>
            </span>
            <span>
              <Icon
                icon="bi:calculator"
                className="edit-icon cursor-pointer "
                onClick={() => {
                  handleOpenCalcModal(record);
                }}
              />
            </span>
          </div>
        </>
      ),
    },
  ];

  const modelColumns = [
    {
      title: "Model",
      dataIndex: "model_name",
      key: "model_name",
      align: "center",
    },
    {
      title: "Model Id",
      dataIndex: "convo_id",
      key: "convo_id",
      align: "center",
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // useEffects
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      // console.log("API call with search term:", searchQuery);
      const input = {
        limit: pageSize,
        page: currentPage,
        drop_down: selectedItem,
        text: searchQuery,
        user_id: userName,
      };
      setPreloaderStatus(true);
      dispatch(apphublistapps(input))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.list_apps);
          const table = value.map((datas) => {
            return {
              dateandtime: datas.created_on,
              email: datas.email_id,
              title: datas.title,
              app: datas.app_name,
              itoken: datas.total_input_tokens,
              otoken: datas.total_output_tokens,
              description: datas.description,
              id: datas.id,
            };
          });

          setRecord(table);
          setPreloaderStatus(false);
        })
        .catch((err) => {
          console.log(err);
          setPreloaderStatus(false);
        });
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedItem, currentPage, pageSize, listupdate]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const input = {
        drop_down: selectedItem,
        text: searchQuery,
        user_id: userName,
      };
      dispatch(apphubtotalrecords(input))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.app_pageno_recent);
          setCount(value);
        })
        .catch((err) => {
          console.log(err);
        });
      setCurrentPage(1);
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedItem, listupdate]);

  const items = [
    {
      label: "App Name",
      value: "app_name",
    },
    {
      label: "Title",
      value: "title",
    },
  ];

  useEffect(() => {
    dispatch(apphubappoptions({}))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.list_model_providers);
        const result = {};
        const options = [];
        value.map((obj) => {
          const key = obj.provider;
          const element = {
            ["value"]: `${obj.provider},,,${obj.model_name},,,${obj.api_type}`,
            ["label"]: obj.model_name,
          };

          if (result[key]) {
            result[key].push(element);
          } else {
            result[key] = [element];
            options.push({ ["value"]: key, ["label"]: key });
          }
        });
        setProviderModelMap(result);
        setProviderOptions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <NavBar />
          <div className="body-margin-top">
            <div className="dashboard-table-body">
              <Col span={24} className=" recent-body">
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-left"
                  >
                    <h4 className="dashboard-credit aivolvex-font">
                      Applications
                    </h4>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      type="primary"
                      size="large"
                      className="register-button margin-bottom-0-5"
                      onClick={() => handleInsertApp()}
                    >
                      <Icon
                        icon="akar-icons:plus"
                        style={{ fontSize: "18px" }}
                        className="margin-top-1"
                      />
                      &nbsp; Register App
                    </Button>
                  </Col>
                </Row>
                <Row className="recent-prompt-search d-flex justify-content-end">
                  <div className="recent-search">
                    {/* <Col>
                      <Select
                        defaultValue={selectedItem}
                        className="recent-search-dropdown"
                        onChange={handleItemClick}
                        options={items}
                      />
                    </Col> */}
                    <Col className="d-flex align-items-center">
                      <Input
                        placeholder="Search"
                        suffix={
                          <Icon
                            icon="material-symbols:search"
                            style={{ fontSize: "20px" }}
                          />
                        }
                        className="recent-search-input"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                      />
                    </Col>
                  </div>
                </Row>
                <Row>
                  {preloaderstatus ? (
                    <>
                      <Col span={24}>
                        <div className="aivolvex-sub-loader h-18">
                          <img src={preloader} width="20%" />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24} className="recent-prompt-table">
                        <Table
                          // className="mt-3 ms-2 costimize-bootstrap-table"
                          className="recent-prompts-table"
                          dataSource={record}
                          columns={columns}
                          // scroll={{ x: 1500 }}
                          pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: count,
                            onChange: handlePageChange,
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      {/* modals */}
      <Modal
        open={inputModal}
        footer={false}
        onCancel={handleCancel}
        width={"45%"}
      >
        <Form
          form={form}
          name="dependencies"
          autoComplete="off"
          style={{
            maxWidth: "100%",
          }}
          layout="vertical"
          onFinish={onFinish}
        >
          {/* <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter title" />
          </Form.Item> */}
          <Form.Item
            label="App Name"
            name="app_name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter App Name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea placeholder="Enter Description" />
          </Form.Item>
          <Form.Item
            label="Provider Name"
            name="provider_name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select a Provider"
              className="model-selector"
              style={{ width: "100%" }}
              mode="multiple"
              defaultValue={form.getFieldValue().provider_name}
              onChange={(val) => {
                const options = [];
                val.map((obj) => options.push(...providerModelMap[obj]));
                setModelOptions(options);
                const result = [];
                selectedModels.map((item) => {
                  if (val.includes(item["value"].split(",,,")[0])) {
                    // tempselectedmodels.push(...item);
                    result.push(item["value"]);
                  }
                });
                form.setFieldsValue({ ["model_name"]: result });
                setSelectedModels(
                  result.map((obj) => ({
                    ["value"]: obj,
                    ["label"]: obj.split(",,,")[1],
                  }))
                );
              }}
              options={providerOptions}
            />
          </Form.Item>
          <Form.Item
            label="Model Name"
            name="model_name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select a Model"
              className="model-selector"
              style={{ width: "100%" }}
              mode="multiple"
              defaultValue={form.getFieldValue().model_name}
              onChange={(val) => {
                setSelectedModels(
                  val.map((obj) => ({
                    ["value"]: obj,
                    ["label"]: obj.split(",,,")[1],
                  }))
                );
              }}
              options={modelOptions}
            />
          </Form.Item>
          {selectedModels.length > 0 ? (
            <TokenCalculator selectedModels={selectedModels} />
          ) : (
            <></>
          )}
          <Form.Item>
            <div className="form-submit-btn">
              <Button
                type="primary"
                htmlType="submit"
                className="button-primary"
              >
                {editstatus ? <>Update App</> : <>Register App</>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal open={openCurlModal} footer={false} onCancel={handleCancel}>
        <Curldetails editurlstatus={editurlstatus} table={curlData} />
      </Modal>

      <Modal
        open={openCalcModal}
        footer={false}
        onCancel={handleCancel}
        width={"45%"}
      >
        <TokenCalculator selectedModels={selectedModels} />
      </Modal>

      <Modal open={openRecordModels} footer={false} onCancel={handleCancel}>
        <Table
          // className="mt-3 ms-2 costimize-bootstrap-table"
          className="recent-prompts-table"
          dataSource={recordModels}
          columns={modelColumns}
          // pagination={{ pageSize: 3 }}
          // scroll={{ x: 1500 }}
        />
      </Modal>
    </>
  );
};
export default memo(DashboardDesktop);
