import { Col, Row } from "antd";
import { memo } from "react";
import { simplifyNumber } from "../../customhook/CustomHoom";
const AppPrice = (props) => {
  return (
    <div className="pricing-table-container">
      <Row>
        <Col span={10}>
          <h1 className="aivolvex-dashboard-model-token model-table-header">
            Application
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Count
          </h1>
        </Col>
        <Col span={5}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Cost
          </h1>
        </Col>
        <Col span={5}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header">
            Share
          </h1>
        </Col>
      </Row>
      <div className="aivolvex-dashboard-modeltoken-scroll">
        {props.applist
          .sort((obj1, obj2) => obj2.total_cost_count - obj1.total_cost_count)
          .map((result) => {
            return (
              <Row className="mt-2">
                <Col span={8}>
                  <label className="aivolvex-dashboard-model-token">
                    {result.app_name}
                  </label>
                </Col>
                <Col span={4} className="aivolvex-right">
                  <label className="aivolvex-dashboard-model-token">
                    {simplifyNumber(result.question_count)}
                  </label>
                </Col>
                <Col span={6} className="aivolvex-right">
                  <label className="aivolvex-dashboard-model-token">
                    {simplifyNumber(result.total_cost_count)}
                  </label>
                </Col>
                <Col span={6} className="aivolvex-right">
                  <label className="aivolvex-dashboard-model-token">
                    {((result.total_cost_count / props.total) * 100).toFixed(1)}
                    %
                  </label>
                </Col>
              </Row>
            );
          })}
      </div>
    </div>
  );
};

export default memo(AppPrice);
