import React from "react";
import { Col, Row } from "antd";
import ModalBody from "./ModalBody";
const Modalinput = () => {
  return (
    <>
      <Row className="modal-body-main">
        <Col span={24}>
          <ModalBody />
        </Col>
      </Row>
    </>
  );
};
export default Modalinput;
